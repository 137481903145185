export const COURIERS = {
  blueexpress: 1,
  shippify: 2,
  chilexpress: 3,
  recibelo: 16,
  urbano: 17
}

export const BILL_STATUS = [
  {
    value: 1,
    name: 'Pagado'
  },
  {
    value: 2,
    name: 'Deuda'
  },
  {
    value: 3,
    name: 'Vencido'
  }
]

export const BILLING_STATES_INDEX = {
  paid: 1,
  debt: 2,
  expired: 3
}

export const PACKAGE_BILL_STATUS = [
  {
    value: 1,
    name: 'Aprobado'
  },
  {
    value: 2,
    name: 'Rechazado'
  }
]

export const PACKAGE_BILL_STATUS_INDEX = [
  {
    aprobado: true,
    rechazado: false
  }
]

export const BANK_ACCOUNT_TYPE = [
  { id: 1, name: 'cuenta corriente' },
  { id: 2, name: 'cuenta vista' },
  { id: 3, name: 'cuenta de ahorro' }
]

export const DOWNLOAD_EXCEL_RETURN_OPTIONS = {
  SUCCESFUL: {
    status: 200,
    data: { body: 'Búsqueda exitosa' }
  },
  NO_DATA: {
    status: 404,
    data: { body: 'No hay facturas para este mes' }
  },
  ERROR: {
    status: 500,
    data: { body: 'Ha ocurrido un problema al buscar los datos' }
  }
}

export const PAYMENT_STATUSES = [
  { id: 1, name: 'paid' },
  { id: 2, name: 'debt' },
  { id: 3, name: 'expired' }
]
