import React from 'react'
import { uploadIcon } from '../../../constants/icons'

const FileUpload = ({
  label,
  inputId,
  inputRef,
  fileUrl,
  handleChange,
  handleButtonClick,
  properties
}) => (
  <label htmlFor={inputId}>
    <div>
      <span>{label}</span>
      <input
        id={inputId}
        type="file"
        className="hidden"
        ref={inputRef}
        onChange={handleChange}
        {...properties}
      />
    </div>
    <button
      type="button"
      className={`flex items-center justify-center gap-2 rounded-sm bg-pinflag-dark px-3 py-1 
        font-bold text-white ${properties?.disabled ? 'cursor-not-allowed opacity-70' : 'hover:opacity-70'}`}
      onClick={handleButtonClick}
    >
      {fileUrl ? 'cambiar' : 'subir'}
      <img src={uploadIcon} alt="archivos" className="h-3" />
    </button>
  </label>
)

export default FileUpload
