import React, { useEffect, useRef, useState } from 'react'
import InputForm from '../../shared/Input/InputForm'
import { PAYMENT_STATUSES } from '../../../constants/billing'
import { updateBills } from '../../../helpers/requests/billing'
import Loader from '../../shared/Loader'
import { BILLING_ICONS, errorIcon } from '../../../constants/icons'
import FormButton from '../../shared/FormButton'
import FileUpload from './FileUpload'

const EditBilling = ({ bill, setShouldFetch }) => {
  const [formValues, setFormValues] = useState({})
  const [apiResponse, setApiResponse] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const invoiceRef = useRef(null)
  const receiptRef = useRef(null)
  const creditNoteRef = useRef(null)

  useEffect(() => {
    setFormValues({ ...bill })
  }, [bill])

  const handleChange = (event) => {
    const { name, value, files } = event.target

    setFormValues({
      ...formValues,
      [name]: files || value
    })
  }

  const handleButtonClick = (type) => () => {
    if (type === 'invoice') invoiceRef.current.click()
    if (type === 'receipt') receiptRef.current.click()
    if (type === 'nc') creditNoteRef.current.click()
  }

  const handleSubmitModal = async () => {
    setIsLoading(true)
    const response = await updateBills({ bill: formValues })
    setShouldFetch(true)
    setApiResponse(response)
    setIsLoading(false)
  }

  const renderIcon = () => {
    if (isLoading) return <Loader />

    const icon = apiResponse?.data.statusCode === 200 ? BILLING_ICONS.paid : errorIcon
    const altText = apiResponse?.data.statusCode === 200 ? 'success' : 'error'

    return (
      <div className='flex flex-col items-center justify-center'>
        <img src={icon} className="mx-auto h-16 w-16" alt={altText} />
        {apiResponse.data.body}
      </div>
    )
  }

  return (
    <form>
      <div className="my-5 grid grid-flow-col grid-rows-3">
        <InputForm
          label="Folio"
          id="folio"
          name="invoice_number"
          value={formValues.invoice_number || ''}
          onChange={handleChange}
          properties={{ required: true }}
        />
        <div className="flex">
          <InputForm
            label="Emisión"
            type="date"
            id="emitModal"
            name="created_at"
            value={formValues.created_at || ''}
            onChange={handleChange}
            properties={{ required: true }}
          />
          <InputForm
            label="Expiración"
            type="date"
            id="expireDateModal"
            name="expires"
            value={formValues.expires || ''}
            onChange={handleChange}
            properties={{ required: true }}
          />
        </div>

        <InputForm
          label="A Cobrar"
          id="totalModal"
          name="pinflag_charge"
          value={formValues.pinflag_charge || 0}
          onChange={handleChange}
        />
        <div className="flex gap-2">
          <FileUpload
            label="Factura"
            inputId="pdfBillingModal"
            inputRef={invoiceRef}
            fileUrl={bill.invoice_url}
            handleChange={handleChange}
            handleButtonClick={handleButtonClick('invoice')}
            properties={{ disabled: true, multiple: false }}
          />
          <FileUpload
            label="Recibo"
            inputId="pdfReceiptModal"
            inputRef={receiptRef}
            fileUrl={bill.receipt_url}
            handleChange={handleChange}
            handleButtonClick={handleButtonClick('receipt')}
            properties={{ disabled: true, multiple: true }}
          />
        </div>
        <FileUpload
          label="Notas de Crédito"
          inputId="nc"
          inputRef={creditNoteRef}
          fileUrl={bill.receipt_url}
          handleChange={handleChange}
          handleButtonClick={handleButtonClick('nc')}
          properties={{ disabled: true, multiple: true }}
        />
        <label
          className="block flex-col gap-2 whitespace-nowrap text-left font-medium"
          htmlFor="statusSelect"
        >
          <span>Status</span>
          <select
            id="statusSelect"
            name="status"
            className="mt-2 block w-full cursor-pointer rounded-sm border border-gray-300 bg-white px-2.5 py-1 text-sm text-gray-900 focus:border-blue-300 focus:ring-blue-300"
            value={formValues.status}
            onChange={(e) => handleChange(e)}
          >
            {PAYMENT_STATUSES?.map((courier) => (
              <option key={courier.name}>{courier.name}</option>
            ))}
          </select>
        </label>
      </div>
      <FormButton text="Guardar" onClick={handleSubmitModal} styles="w-full my-2 py-1" />
      {apiResponse.status && renderIcon()}
    </form>
  )
}

export default EditBilling
