import React from 'react'
import { BILLING_ICONS, errorIcon } from '../../constants/icons'
import Loader from '../shared/Loader'
import Table from '../shared/Table/Table'
import TableRow from '../shared/Table/TableRow'
import FormButton from '../shared/FormButton'
import { createExcel } from '../../utils/files'

const BillingModal = ({ text, isLoading = true }) => {
  const renderIcon = () => {
    if (isLoading) return <Loader />

    const icon = text?.status === 200 ? BILLING_ICONS.paid : errorIcon
    const altText = text?.status === 200 ? 'success' : 'error'

    return <img src={icon} className="mx-auto h-16 w-16" alt={altText} />
  }

  const renderMissingPackages = () => {
    if (!Array.isArray(text.data?.missingPackages) || !text.data.missingPackages.length) return null

    return (
      <div className="relative h-96 overflow-x-hidden">
        <div className="sticky top-0 z-40 mb-5 bg-white drop-shadow-sm">Pedidos no encontrados</div>
        <Table headers={['Tracking']} roundedTop>
          {text.data.missingPackages.map((item) => (
            <TableRow
              key={item.tracking_number}
              onClick={() => {}}
              items={[<div className="flex w-32 items-center">{item.tracking_number || '-'}</div>]}
            />
          ))}
        </Table>
      </div>
    )
  }

  const renderAnomalies = () => {
    if (!Array.isArray(text.data?.anomalies) || !text.data.anomalies.length) return null

    return (
      <div className="h-96 overflow-x-hidden">
        <div className="sticky top-0 z-40 mb-5 overflow-x-hidden bg-white drop-shadow-sm">Pedidos extraños</div>
        <Table headers={['Tracking', 'Peso', 'Cobrado']} roundedTop>
          {text.data.anomalies.map((item) => (
            <TableRow
              key={item.tracking_number}
              onClick={() => {}}
              items={[
                <div className="flex w-32 items-center">{item.tracking_number || '-'}</div>,
                <div className="flex w-32 items-center">{item.declared_weight || '-'}</div>,
                <div className="flex w-32 items-center">{item.billed || '-'}</div>
              ]}
            />
          ))}
        </Table>
      </div>
    )
  }

  const exportMissingAndSuspiciousPackages = () => {
    const missingPackages = text.data?.missingPackages || null
    const suspiciousPackages = text.data?.anomalies || null
    if (missingPackages) createExcel(missingPackages, 'Sheet1', 'Paquetes no encontrados')
    if (suspiciousPackages) createExcel(suspiciousPackages, 'Sheet1', 'Paquetes sospechosos')
  }

  const isTherePackagesToCheck = () =>
    text.data?.missingPackages?.length > 0 || text.data?.anomalies?.length

  const renderContent = () => (
      <div className="flex flex-col justify-center text-center">
        {renderIcon()}
        {!isLoading && (
          <div className="">
            <div>{typeof text.data?.body === 'string' ? text.data.body : ''}</div>
            <div className="mt-5 flex">
              {renderMissingPackages()}
              {renderAnomalies()}
            </div>
            {isTherePackagesToCheck() && (
                <div className="flex justify-end">
                  <FormButton
                    text="Exportar"
                    styles="w-24 py-1 mt-5 mr-10 justify-self-end"
                    onClick={exportMissingAndSuspiciousPackages}
                  />
                </div>
              )}
          </div>
        )}
      </div>
    )

  return <div className="p-4">{renderContent()}</div>
}

export default BillingModal
