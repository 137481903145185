import * as XLSX from 'xlsx'
import { COURIERS, DOWNLOAD_EXCEL_RETURN_OPTIONS } from '../../constants/billing'
import { createExcel, normalizeJsonData } from '../../utils/files'
import { savePackages, savePackagesToDB } from '../requests/billing'
import { parseFirstDay, parseLastDay } from '../../utils/dates'

const parseChilexpress = (json, courier) => {
  const uniqueClientReferences = json.map((item) => {
    let weightUsed = 0
    // eslint-disable-next-line react-func/max-combined-conditions
    if (item.largo_pza > 60 && item.ancho_pza > 60 && item.alto_pza > 60) {
      weightUsed = item.peso_volumen_cxp
    } else {
      weightUsed = item.peso_fisico_cxp
    }
    const amountBilled = item.netocondsctofinal || item.netovf
    return {
      tracking_number: String(item.documento_hijo.replace('OTR-', '')),
      length: item.largo_pza,
      height: item.alto_pza,
      width: item.ancho_pza,
      weight_billed: Math.ceil(weightUsed),
      billed: Math.ceil(amountBilled),
      courier_id: COURIERS[courier]
    }
  })
  return uniqueClientReferences
}

const getWeightBlueExpress = (item) => {
  if(item.kilos >= item.volumen) return Math.ceil(item.kilos)
  return Math.ceil(item.volumen)
}

const parseBlueExpress = (json, courier) => {
  const uniqueClientReferences = json.map((item) => ({
    tracking_number: String(item.guia),
    weight_billed: getWeightBlueExpress(item),
    volume: item.volumen,
    billed: Math.ceil(item.neto),
    courier_id: COURIERS[courier]
  }))
  return uniqueClientReferences
}

const parseShippify = (json, courier) => {
  const uniqueClientReferences = json.map((item) => ({
    tracking_number: String(item.iddeentrega),
    weight_billed: Math.ceil(item['pesototaldeartículos']),
    volume: 0,
    billed: Math.ceil(item['preciodelacompañía']),
    courier_id: COURIERS[courier]
  }))
  return uniqueClientReferences
}

function getOrderUrbano(order) {
  return order.replace(/Y/g, '')
}

const parseUrbano = (json, courier) => {
  const uniqueClientReferences = json.map((item) => ({
    tracking_number: String(getOrderUrbano(item.guiaelectronica)),
    weight_billed: Math.ceil(parseFloat(item.pesoespecifico.replace('.', ''))),
    volume: Math.ceil(parseFloat(item.pesovolumen.replace('.', ''))),
    billed: Math.ceil(parseFloat(item.valorneto.replace('.', ''))),
    courier_id: COURIERS[courier]
  }))
  return uniqueClientReferences
}

const recibeloBilledPrice = (item) => {
  if(!item.tags) return Math.ceil(item.tarifacliente)
  const price = item.tags.includes('ND') ||
  item.tags.includes('Next Day') ||
  item.tags.includes('next day')||
  item.tags.includes('nd') ||
  item.tags.includes('NextDay') ||
  item.tags.includes('nextday') ? 2300 : Math.ceil(item.tarifacliente)
  return price
}

const parseRecibelo = (json, courier) => {
  const uniqueClientReferences = json.map((item) => ({
    tracking_number: String(item.idinterna),
    weight_billed: 1,
    volume: 0,
    billed: recibeloBilledPrice(item),
    courier_id: COURIERS[courier]
  }))
  return uniqueClientReferences
}

const courierParsers = {
  chilexpress: parseChilexpress,
  blueexpress: parseBlueExpress,
  shippify: parseShippify,
  urbano: parseUrbano,
  recibelo: parseRecibelo
}

const sheetOptions = {
  urbano: { sheetIndex: 0, options: { range: 7 } },
  recibelo: { sheetIndex: 0, options: {} },
  default: { sheetIndex: 1, options: {} }
}

export const parseJsonOrders = (courier, workbook) => {
  const { sheetIndex, options } = sheetOptions[courier] || sheetOptions.default
  const sheetName = workbook.SheetNames[sheetIndex]
  const workSheet = workbook.Sheets[sheetName]

  const ordersData = XLSX.utils.sheet_to_json(workSheet, options)
  const normalizedData = normalizeJsonData(ordersData)
  const parserFunction = courierParsers[courier]

  if (!parserFunction) {
    throw new Error('Courier Desconocido')
  }

  const parsedData = parserFunction(normalizedData, courier)
  if (!parsedData[0]?.tracking_number || !parsedData[0]?.billed || !parsedData[0]?.weight_billed) {
    throw new Error('Archivo con formato incorrecto')
  }

  return parsedData
}


const parseExportedExcel = (jsonData) =>{
const parsedData = jsonData.map((row) => (
  {
    Courier: row.courier,
    Guia: row.tracking_number,
    Comuna: row.city_name,
    'Fecha Entrega': row.arrival_date,
    'Peso Declarado': row.declared_weight,
    'Peso Cobrado': row.weight_billed,
    Cobrado: row.billed,
    'Courier Price': row.courier_price,
    'Base Price': row.base_price,
    'Aprobado Peso': row.approved_by_weight,
    'Aprobado Precio': row.approved_by_price
  }
))
return parsedData
}

export const handleDownload = (jsonData, name) => {
  const parsedData = parseExportedExcel(jsonData)
  const rejectedPackages = parsedData.filter((parcel) =>(
    !parcel['Aprobado Peso'] || !parcel['Aprobado Precio']
  )
  )
  createExcel(parsedData, 'Sheet1', `${name}_RESULTADO`)
  createExcel(rejectedPackages, 'Sheet1', `${name}_RECHAZADOS_RESULTADO`)
}

const parseBillingsData = (jsonData) => {
  const parsedData = jsonData.map((row) => (
    {
      Holding: row['Holding.name'],
      'Período': `${row.month}-${row.year}`,
      Expira: row.expires,
      Estado: row.status,
      'Cobrado Courier': row.net_value,
      'Cobrado por Pinflag': row.pinflag_charge,
      'Base price': row.base_price_total,
      'Folio': row.invoice_number,
      'URL folio': row.invoice_url,
      'URL recibo': row.receipt_url

    }
  ))
  return parsedData
}

export const handleBillingsDownload = (jsonData) => {
  const parsedData = parseBillingsData(jsonData)
  if(parsedData.length < 1 ) return DOWNLOAD_EXCEL_RETURN_OPTIONS.NO_DATA
  createExcel(parsedData, 'Sheet1', 'Detalle')
  return DOWNLOAD_EXCEL_RETURN_OPTIONS.SUCCESFUL
}

const sendPackagesInBatches = (response) => {
  const batchSize = 5000
  for (let i = 0; i < response.length; i += batchSize) {
    const batch = response.slice(i, i + batchSize)
    savePackages({ sales: batch })
  }
}

export const fetchDataInBatches = async (startDate, endDate) => {
  let currentStart = new Date(startDate)
  const promises = []

  while (currentStart <= new Date(endDate)) {
    let currentEnd = new Date(currentStart)
    currentEnd.setDate(currentEnd.getDate() + 7)

    if (currentEnd > new Date(endDate)) currentEnd = new Date(endDate)

    const parsedStart = parseFirstDay(currentStart)
    const parsedEnd = parseLastDay(currentEnd)
    promises.push(
      savePackagesToDB({ startDate: parsedStart, endDate: parsedEnd })
        .then(response => {
          if (response.length > 10000) return sendPackagesInBatches(response)
          return savePackages({ sales: response })
        })
    )
    currentStart = new Date(currentEnd)
    currentStart.setDate(currentStart.getDate() + 1)
  }
  await Promise.all(promises)
}
