import React from 'react'
import Select from '../shared/Input/Select'
import InputForm from '../shared/Input/InputForm'
import { COURIERS } from '../../constants/couriers'
import FormButton from '../shared/FormButton'

const FileUploadSection = ({
  setCourier,
  file,
  setFile,
  inputRef,
  saveData,
  loading,
  handleCreateBills,
  handleGetBillData,
  setShowModal,
  setIsResponse
}) => (
  <div className="mx-10 mb-2 flex items-start">
    <form className="flex flex-col items-start gap-4">
      <div className="flex w-full gap-4">
        <Select
          options={COURIERS}
          label="Courier"
          setter={setCourier}
          withName
          defaultValue={COURIERS[0].name}
        />
        <InputForm
          label="Subir Factura"
          id="billing-upload"
          type="file"
          ref={inputRef}
          size='w-52'
          accept=".xls, .xlsx"
          onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
          required
        />
        <FormButton
          isEditing={loading || !file}
          text={!loading ? 'Guardar' : 'Subiendo...'}
          onClick={saveData}
          type="button"
          styles="px-4 h-10 mt-5 text-base"
          disabled={loading || !file}
        />
      </div>
      <div className="flex h-12 items-center gap-4">
        <FormButton
          isEditing={loading}
          text="Buscar pedidos"
          onClick={() => {
            setShowModal(true)
            setIsResponse(false)
          }}
          type="button"
          styles="px-4 h-10 mt-5 text-base"
        />
        <FormButton
          isEditing={loading}
          text="Crear Facturas"
          onClick={handleCreateBills}
          type="button"
          styles="px-4 h-10 mt-5 text-base"
        />
        <FormButton
          isEditing={loading}
          text="Detalle General"
          onClick={handleGetBillData}
          type="button"
          styles="px-4 h-10 mt-5 text-base"
        />
      </div>
    </form>
  </div>
)

export default FileUploadSection
