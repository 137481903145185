import React, { useState, useEffect, useCallback } from 'react'
import { isSubstring } from '../../../utils/strings'
import { BILLING_STATES_INDEX, BILL_STATUS } from '../../../constants/billing'
import Table from '../../shared/Table/Table'
import TableRow from '../../shared/Table/TableRow'
import ToolTip from '../../shared/ToolTip'
import SearchBar from '../../shared/SearchBar'
import Pagination from '../../shared/Pagination'
import ActionsBar from '../../shared/Table/ActionsBar'
import Modal from '../../shared/Modal/Modal'
import EditBilling from './EditBilling'
import { handleDownload } from '../../../helpers/processBilling/OrdersBilling'
import { createBills, getBilledPackages, getBills } from '../../../helpers/requests/billing'
import { getFormattedDate } from '../../../utils/dates'
import FormButton from '../../shared/FormButton'
import ResponseModal from '../../shared/Modal/ResponseModal'
import useFetch from '../../../hooks/useFetch'
import Loader from '../../shared/Loader'

const HoldingBilling = ({ holding }) => {
  const [billings, setBillings] = useState([])
  const [modalData, setModalData] = useState({})
  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [showResponseModal, setShowResponseModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const [shouldFetch, setShouldFetch] = useState(false)

  const initialzeData = useCallback((data) => {
    setBillings(data)
    setSearchResult(data)
  }, [])

  const { isLoading } = useFetch(getBills, initialzeData, holding.id)

  useEffect(() => {
    if (shouldFetch) {
      getBills(holding.id)
        .then(initialzeData)
        .finally(() => setShouldFetch(false))
    }
  }, [shouldFetch, initialzeData, holding.id])

  const debtStatusColor = (status) => {
    let bgColor = ''
    if (status === 'paid') bgColor = 'bg-green'
    else if (status === 'debt') bgColor = 'bg-yellow'
    else if (status === 'expired') bgColor = 'bg-red'
    return <div className={`w-1 rounded-full p-1 ${bgColor}`} />
  }

  const debtStatus = (status) => {
    if (status === 'paid') return 'Pagado'
    if (status === 'debt') return 'Deuda'
    if (status === 'expired') return 'Vencido'
    return 'No manejado'
  }
  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const filteredBills = billings.filter(
        (billing) =>
          isSubstring(billing.id.toString(), searchTerm) ||
          (billing.status && isSubstring(billing.status, searchTerm)) ||
          (billing.range && isSubstring(billing.range, searchTerm)) ||
          (billing.folio && isSubstring(billing.folio.toString(), searchTerm))
      )
      setSearchResult(filteredBills)
    } else {
      setSearchResult(billings)
    }
  }
  const handleCloseResultModal = () => {
    if (showResponseModal) setShowResponseModal(false)
  }
  const stateFilteredBills =
    selectedFilters.length > 0
      ? searchResult.filter((bill) => selectedFilters.includes(BILLING_STATES_INDEX[bill.status]))
      : searchResult

  const currentBills = stateFilteredBills.slice(pageSize * (page - 1), pageSize * page)

  const downloadDetail = async (billingId) => {
    const { newPackages: holdingBillDetail } = await getBilledPackages({
      holdingId: Number(holding.id),
      billingId
    })
    handleDownload(holdingBillDetail, holding.name)
  }

  const handleCreateBills = async () => {
    try {
      setError(false)
      const response = await createBills(holding.id)
      if (response.status !== 200) setError(true)
      setShouldFetch(true)
      setModalData(response)
      setShowModal(true)
    } catch (e) {
      setError(true)
    }
  }

  const handleCloseModal = () => {
    if (showModal) setShowModal(false)
  }

  const tableContent = () => (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          headers={[
            'Status',
            'Período',
            'Folio',
            'Emisión',
            'Vencimiento',
            'Cobrado',
            'A Cobrar',
            'Gan.Teórico',
            'Gan.Real',
            'NC',
            'Factura',
            'Detalle',
            'Comprobante',
            ''
          ]}
        >
          {currentBills?.map((billing) => (
            <TableRow
              key={billing.id}
              onClick={() => {}}
              items={[
                <div className="relative items-center">
                  <ToolTip
                    backgroundColor="bg-pinflag-dark"
                    textColor="text-white"
                    hoverElement={debtStatusColor(billing.status)}
                    up
                    right
                  >
                    <div>{debtStatus(billing.status)}</div>
                  </ToolTip>
                </div>,
                <div className="flex items-center">
                  {billing.month}-{billing.year}
                </div>,
                <div className="flex items-center">{billing.invoice_number || '-'}</div>,
                <div className="flex items-center">
                  {getFormattedDate(billing.created_at) || '-'}
                </div>,
                <div className="flex items-center">{getFormattedDate(billing.expires) || '-'}</div>,
                <div className="flex items-center">{billing.net_value || '-'}</div>,
                <div className="flex items-center">{billing.pinflag_charge || '-'}</div>,
                <div className="flex items-center">
                  {billing.base_price_total - billing.net_value || '-'}
                </div>,
                <div className="flex items-center">
                  {billing.pinflag_charge - billing.net_value || '-'}
                </div>,
                <div className="flex items-center">
                  {billing.nc ? (
                    <a href={billing.nc} className="font-bold decoration-solid">
                      abrir
                    </a>
                  ) : (
                    'N/A'
                  )}
                </div>,
                <div className="flex items-center">
                  {billing.billingPdf ? (
                    <a href={billing.billingPdf} className="font-bold decoration-solid">
                      pdf
                    </a>
                  ) : (
                    'N/A'
                  )}
                </div>,
                <div className="flex items-center">
                  <button
                    type="button"
                    className="font-bold decoration-solid"
                    onClick={() => {
                      downloadDetail(billing.id)
                    }}
                  >
                    xlsx
                  </button>
                </div>,
                <div className="flex items-center">
                  {billing.receipt ? (
                    <a href={billing.detail} className="font-bold decoration-solid">
                      pdf
                    </a>
                  ) : (
                    'N/A'
                  )}
                </div>,
                <button
                  type="button"
                  onClick={() => {
                    setShowResponseModal(true)
                    setModalData({ ...billing })
                  }}
                >
                  editar
                </button>
              ]}
            />
          ))}
        </Table>
      )}
    </div>
  )

  return (
    <div className="flex h-screen flex-col bg-light-grey">
      <div className=" my-5">
        <div className="mx-10 my-5 flex justify-end">
          <ResponseModal show={showModal} handleClose={handleCloseModal} error={error} />
          <FormButton
            isEditing={false}
            text="Crear Factura"
            onClick={() => {
              handleCreateBills()
            }}
            type="button"
            styles="mx-2 py-2 px-2 text-base"
          />
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        </div>
        <ActionsBar
          statusFilters={BILL_STATUS}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
        {tableContent()}
      </div>
      <div className="mx-10 flex h-24 place-content-end">
        {stateFilteredBills.length > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={stateFilteredBills.length}
          />
        )}
      </div>
      <Modal show={showResponseModal} handleClose={handleCloseResultModal} title="Editar Factura">
        <EditBilling bill={modalData} setShouldFetch={setShouldFetch} />
      </Modal>
    </div>
  )
}

export default HoldingBilling
