import React from 'react'

import FormButton from '../shared/FormButton'
import { searchIcon } from '../../constants/icons'

const SearchAndEditControls = ({
  searchBarInput,
  searchHandler,
  isEdited,
  editPackages,
  cancelEdit,
  setShouldFetch,
  loading
}) => {
  const handleChange = (event) => {
    searchHandler(event.target.value)
    if(event.target.value === '') setShouldFetch(true)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.target.value.length > 2 ) {
      setShouldFetch(true)
    }
  }
  return (
    <div className="mx-10 flex items-center justify-end gap-4">
      <div className="relative mx-auto w-fit">
        <input
          className="rounded-lg py-2 pl-8 pr-4"
          type="text"
          placeholder="Enter para buscar"
          value={searchBarInput}
          onChange={(search) => handleChange(search)}
          onKeyDown={(search) => handleKeyDown(search)}
        />
        <img className="absolute top-3 left-2 w-4" alt="icon-input" src={searchIcon} />
      </div>
      <FormButton
        text={!isEdited ? 'Editar' : 'Guardar cambios'}
        onClick={editPackages}
        type="button"
        styles="py-2 px-2 text-base"
        disabled={loading}
      />
      <FormButton
        isEditing={!isEdited}
        text="Cancelar"
        onClick={cancelEdit}
        type="button"
        styles="py-2 px-2 text-base"
        disabled={!isEdited}
      />
    </div>
  )
}

export default SearchAndEditControls
