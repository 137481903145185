import apiClient from '../../axiosConfig/apiClient'
import opsClient from '../../axiosConfig/opsClient'
import {
  PROCESS_PACKAGES_URL,
  HANDLE_PACKAGES_URL,
  HANDLE_BILLS_URL
} from '../../constants/endpointUrls/api'

export const savePackagesToDB = async (body) => {
  const response = await apiClient.post(PROCESS_PACKAGES_URL, body)
  return response.data
}

export const processOrders = async (body) => {
  const response = await apiClient.post(PROCESS_PACKAGES_URL, body)
  return response.data
}

export const savePackages = async (body) => {
  const { data, status } = await opsClient.post(HANDLE_PACKAGES_URL, body)
  return { data, status }
}

export const updatePackages = async (body) => {
  const { data, status } = await opsClient.put(HANDLE_PACKAGES_URL, body)
  return { data, status }
}

export const getBilledPackages = async (body) => {
  const response = await opsClient.get(
    `${HANDLE_PACKAGES_URL}?holdingId=${body.holdingId || ''}&billingId=${body.billingId || ''}&page=
    ${body.page || 1}&searchParam=${body.searchParam || ''}`
  )
  return response.data
}

export const createBills = async (body) => {
  const { data, status } = await opsClient.post(HANDLE_BILLS_URL, body)
  return { data, status }
}

export const getBills = async (body) => {
  const response = await opsClient.get(`${HANDLE_BILLS_URL}?holdingId=${body}`)
  return response.data
}

export const updateBills = async (body) => {
  const { data, status } = await opsClient.put(HANDLE_BILLS_URL, body)
  return { data, status }
}
