import React, { useCallback, useEffect, useRef, useState } from 'react'

import {
  createBills,
  getBilledPackages,
  getBills,
  savePackages,
  updatePackages
} from '../helpers/requests/billing'
import { handleBillingsDownload, parseJsonOrders } from '../helpers/processBilling/OrdersBilling'
import useFetch from '../hooks/useFetch'
import { updateElementArrayOfObjects } from '../utils/array'
import { parseWorkbook, readFile } from '../utils/files'
import ModalHandler from '../components/Billing/ModalHandler'
import FileUploadSection from '../components/Billing/FileUploadSection'
import SearchAndEditControls from '../components/Billing/SearchAndEditControls'
import TableSection from '../components/Billing/TableSection'

const Billing = () => {
  const [file, setFile] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [isResponse, setIsResponse] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [courier, setCourier] = useState('')
  const [loading, setLoading] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [searchParam, setSearchParam] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [editedPackages, setEditedPackages] = useState([])
  const [editDataBackup, setEditDataBackup] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [shouldFetch, setShouldFetch] = useState(false)
  const [totalPackagesCount, setTotalPackagesCount] = useState(0)

  const inputRef = useRef(null)

  const initializeData = useCallback((data) => {
    const { newPackages, count } = data
    setSearchResult(newPackages)
    setTotalPackagesCount(count)
  }, [])

  const { isLoading } = useFetch(getBilledPackages, initializeData, '')

  const setModalData = (data) => {
    setShowModal(true)
    setIsResponse(true)
    setModalMessage(data)
  }

  const handleClearFile = () => {
    setFile(null)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const resetData = () => {
    setEditedPackages([])
    setIsEdited(false)
    setShouldFetch(true)
    setSearchParam('')
    setPage(0)
    handleClearFile()
  }

  const saveData = async () => {
    setLoading(true)
    if (!file) {
      setLoading(false)
      return
    }
    try {
      const data = await readFile(file)
      const workbook = parseWorkbook(data)
      const parsedData = parseJsonOrders(courier, workbook)
      const savedPackagesResponse = await savePackages({ sales: parsedData, courier })
      setModalData(savedPackagesResponse)
      setShouldFetch(true)
    } catch (e) {
      setModalData({ status: 500, data: {body: e.message }})
    }
    handleClearFile()
    setLoading(false)
  }

  useEffect(() => {
    if (shouldFetch) {
      getBilledPackages({ page, searchParam })
        .then(initializeData)
        .finally(() => setShouldFetch(false))
    }
  }, [shouldFetch, initializeData, page, searchParam])

  const editPackages = async () => {
    if (isEdited) {
      const result = await updatePackages({ editedPackages })
      setModalData(result)
      resetData()
    } else {
      setIsEdited(true)
      setEditDataBackup([...searchResult])
    }
  }

  const cancelEdit = () => {
    resetData()
    setSearchResult([...editDataBackup])
  }

  const searchHandler = (term) => {
    setSearchParam(term)
  }

  const handleCheckboxChange = (item, field) => {
    const newEditedPackages = updateElementArrayOfObjects(
      editedPackages,
      { ...item, [field]: !item[field] },
      'id'
    )
    setEditedPackages(newEditedPackages)
    const updatedProcessedFile = searchResult.map((sale) => {
      if (sale.id === item.id) {
        return { ...sale, [field]: !sale[field] }
      }
      return sale
    })
    setSearchResult(updatedProcessedFile)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setIsResponse(false)
  }

  const handleCreateBills = async () => {
    setLoading(true)
    setShowModal(true)
    setIsResponse(true)
    try {
      const response = await createBills()
      setModalData(response)
    } catch (e) {
      setModalData({ status: 500, data:{body: `Error al crear Facturas.\n${e}`} })
    }
    setLoading(false)
  }

  const handleGetBillData = async () => {
    setLoading(true)
    try {
      const response = await getBills('')
      const message = handleBillingsDownload(response)
      setModalData(message)
    } catch (e) {
      setModalData({ statusCode: 500, data:{body: `Error al traer Facturas.\n${e}`} })
    }
    setLoading(false)
  }

  return (
    <div className="relative flex h-screen flex-col bg-light-grey">
      <div className="mx-10 my-5">
        <div className="mb-8 text-left text-xl font-semibold">Facturas de Courier</div>
        <ModalHandler
          showModal={showModal}
          isResponse={isResponse}
          modalMessage={modalMessage}
          loading={loading}
          handleCloseModal={handleCloseModal}
        />
        <div className="mb-4 flex items-end justify-between">
          <FileUploadSection
            courier={courier}
            setCourier={setCourier}
            file={file}
            setFile={setFile}
            inputRef={inputRef}
            saveData={saveData}
            loading={loading}
            handleCreateBills={handleCreateBills}
            handleGetBillData={handleGetBillData}
            setShowModal={setShowModal}
            setIsResponse={setIsResponse}
          />
          <SearchAndEditControls
            searchBarInput={searchParam}
            searchHandler={searchHandler}
            setShouldFetch={setShouldFetch}
            isEdited={isEdited}
            editPackages={editPackages}
            cancelEdit={cancelEdit}
            loading={loading}
          />
        </div>
        <TableSection
          headers={[
            'Courier',
            'Company',
            'OV',
            'OT',
            'Comuna',
            'Precio envío',
            'Peso declarado',
            'Peso cobrado',
            'Cobrado',
            'BasePrice',
            'CourierPrice',
            'Aprobado por Peso',
            'Aprobado por Precio',
            'Pagado',
            'Activo'
          ]}
          isLoading={isLoading}
          currentBills={searchResult}
          handleCheckboxChange={handleCheckboxChange}
          isEdited={isEdited}
          page={page}
          setPage={(data) => {
            setPage(data)
            setShouldFetch(true)
          }}
          pageSize={pageSize}
          setPageSize={setPageSize}
          itemsCount={totalPackagesCount}
        />
      </div>
    </div>
  )
}

export default Billing
